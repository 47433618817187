/**
 * 修改默认的错误状态码 (默认是500)
 * @author wwl
 * @created 2024/10/28
 * @modified 2024/10/28
 */
// import LRU from 'lru-cache';
// import { ServerMiddleware } from '@nuxt/types';

// ~/server-middleware/error-handler.js
// export default function (res: any)
// export default function (res: any) {
//   console.log('13-13', res);
//   if (res && !res.headersSent) {
//     // 设置状态码为500，表示服务器错误
//     res.statusCode = 503; // 503
//     // 设置响应头，表示内容类型为文本
//     res.setHeader('Content-Type', 'text/plain');
//     // 发送状态码和错误信息
//     res.end(`Server Error-18`);
//   }
// }

import { ServerMiddleware } from '@nuxt/types';
// import axios from 'axios';
// import uuid from 'uuid';
// import _ from 'lodash';

const exposureMiddleware: ServerMiddleware = (req, res, next) => {
  // console.log('33-33', res);
  res.on('close', () => {
    // console.log('35-35', res.statusCode);
    if (res.statusCode === 500) {
      // 这里处理服务器错误，比如发送通知邮件或者记录错误日志
      console.error('服务器发生错误，状态码：', res.statusCode);
      // 可以添加额外的处理逻辑
      res.statusCode = 503;
      res.end(`Server Error-18`);
    }
  });
  // if (_res && !_res.headersSent) {
  //   // 设置状态码为500，表示服务器错误
  //   _res.statusCode = 503; // 503
  //   // 设置响应头，表示内容类型为文本
  //   _res.setHeader('Content-Type', 'text/plain');
  //   // 发送状态码和错误信息
  //   _res.end(`Server Error-18`);
  // }

  next();
};

export default exposureMiddleware;
