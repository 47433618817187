import _ from 'lodash';
import { Middleware } from '@nuxt/types';
import { $cookies } from '~/util/api';

const authenticated: Middleware = (ctx) => {
  const token = $cookies.get('access_token');
  const redirectUrl = _.get(ctx, 'route.fullPath', '/');
  if (!token) {
    return ctx.redirect(`/login/?redirect=${encodeURIComponent(redirectUrl)}`);
  }
};

export default authenticated;
