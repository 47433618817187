import { Context } from '@nuxt/types';

/**
 * i18n
 * @author wwl
 * @created 2023/12/28
 * @modified 2023/12/28
 */
export default function ({ store, app, route, isHMR }: any) {
  if (isHMR) return;
  // lang: 'zh-cn'
  // app.head.htmlAttrs.lang =
  const { lang = 'zh' } = route.query;
  // console.log('middleware-i18n设置语言', lang);
  // console.log('15-15-', app.head.htmlAttrs);
  app.head.htmlAttrs.lang = lang == 'zh' ? 'zh-cn' : 'en';
  // console.log('17-17', app.head.htmlAttrs);
  // 设置语种
  store.commit('i18n/SET_LANG', lang); // set store

  if (app.i18n && 'locale' in app.i18n) {
    (app.i18n as any).locale = lang;
  }

  // return redirect(route.fullPath);
}
